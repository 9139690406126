import {
  Button,
  Card,
  Col,
  Comment,
  Divider,
  Empty,
  Form,
  Image,
  Input,
  Modal,
  notification,
  Pagination,
  Row,
  Select,
  Skeleton,
  Space,
  Tag,
  Timeline,
  Typography,
} from "antd"
import React, { useEffect, useMemo, useReducer, useState } from "react"
import { SearchOutlined } from "@ant-design/icons"
import { useHistory, useLocation } from "react-router-dom"
import { useQueryClient } from "react-query"
import { Container } from "../components/Container"
import { useGetPerson } from "../network/usePerson"
import {
  useEnrolSomeoneElseToPublicationMutation,
  useEnrolToIntegrationMutation,
  useEnrolToPublicationMutation,
  useGetEvents,
  useGetPublications,
  useGetPublicationsFilters,
} from "../network/usePublications"
import { generateKey } from "../hooks/generateKey"
import { cardFactory } from "../helpers/cardFactory"
import { useAwaitScorm } from "../hooks/useAwaitScorm"
import { useGetQueryParam } from "../hooks/useGetQueryParam"
import { MinifiedCard } from "../components/cards/MinifiedCard"
import { TrainingModal } from "../components/TrainingModal"
import { SmallCard } from "../components/cards/SmallCard"
import { useGetTeams } from "../network/useTeams"

function exploreReducer(state, action) {
  const { type, field, payload } = action
  switch (type) {
    case "initialize":
      return {
        ...state,
        initialized: true,
        top: payload.slice(0, 4),
      }
    case "field": {
      return {
        ...state,
        [field]: payload,
      }
    }
    case "filter-field": {
      return {
        ...state,
        filters: {
          ...state.filters,
          [field]: payload,
        },
      }
    }

    default: {
      throw new Error(`Unsupported action type: ${type}`)
    }
  }
}

export function Explore() {
  const { get, set } = useGetQueryParam("topics")
  const [state, dispatch] = useReducer(exploreReducer, {
    initialized: false,
    top: null,
    publications: null,
    filters: {
      keyword: null,
      topics: get(),
      sort: undefined,
    },
    enrolmentUid: null,
    scormToFind: null,
    visible: false,
    training: false,
    page: 1,
    pageSize: 6,
  })
  const [modalPub, setModalPub] = useState(null)
  const [teamMemberToEnrol, setTeamMemberToEnrol] = useState(null)

  const personQuery = useGetPerson({ authenticated: true })
  const publicationsQuery = useGetPublications(state.filters)
  const filtersQuery = useGetPublicationsFilters()
  const integrationMutation = useEnrolToIntegrationMutation()
  const publicationMutation = useEnrolToPublicationMutation()
  const enrolSomeoneElseMutation = useEnrolSomeoneElseToPublicationMutation()
  const { pathname } = useLocation()
  const { push } = useHistory()
  const awaitedScorm = useAwaitScorm(state?.scormToFind)
  const eventsQuery = useGetEvents()
  const queryClient = useQueryClient()
  const teamsQuery = useGetTeams()

  useEffect(() => {
    window?.scrollTo(0, 0)
  }, [pathname])

  useEffect(() => {
    if (!publicationsQuery.isLoading && publicationsQuery.data) {
      dispatch({ type: "initialize", payload: publicationsQuery.data })
    }
  }, [publicationsQuery.isLoading, publicationsQuery.data])

  useEffect(() => {
    if (awaitedScorm?.data?.synced && state?.enrolmentUid) {
      notification.success({
        key: state?.enrolmentUid,
        duration: null,
        message: `Successfully enrolled to ${modalPub?.title}`,
        description: "We're setting up a few things in the background to optimize your learning experience",
        btn: (
          <Button
            onClick={() => {
              notification.close(state?.enrolmentUid)
              push(`/externals/${state?.enrolmentUid}?tab=Modules`)
            }}
            type="primary"
            size="small"
          >
            Start learning
          </Button>
        ),
      })
      queryClient.invalidateQueries("publications")
      setModalPub(null)
      dispatch({ type: "field", field: "visible", payload: false })
    }
  }, [awaitedScorm.data])

  const listOfTeamsIManage = useMemo(() => {
    if (!teamsQuery.isLoading && teamsQuery.data && !teamsQuery.isError) {
      let membersOfTeamsIManage = []
      teamsQuery.data.map((team) => {
        if (team?.me?.isAdmin) {
          membersOfTeamsIManage = [
            ...membersOfTeamsIManage,
            {
              label: team.name,
              options: team?.members?.map((person) => ({
                label: `${person?.member?.person?.firstName || ""} ${person?.member?.person?.lastName || ""}`,
                value: person?.member?.personUid,
              })),
            },
          ]
        }
      })
      if (!membersOfTeamsIManage.length) return null

      return membersOfTeamsIManage
    }
    return null
  }, [teamsQuery.data, teamsQuery.isLoading, teamsQuery.isError])

  const handleSelfEnrolment = async (eventOrPersonUid) => {
    let personUid
    if (typeof eventOrPersonUid === "string") {
      personUid = eventOrPersonUid
    }
    const conditionalMutation = typeof personUid === "string" ? enrolSomeoneElseMutation : publicationMutation
    if (modalPub?.isIntegration) {
      integrationMutation.mutate(
        {
          integrationId: modalPub?.integrationId,
          externalId: modalPub?.id,
          data: personUid ? { personUid } : null,
        },
        {
          onSuccess: (res) => {
            if (res?.data?.uid && !personUid) {
              dispatch({
                type: "field",
                field: "scormToFind",
                payload: modalPub?.id,
              })
              dispatch({
                type: "field",
                field: "enrolmentUid",
                payload: res?.data?.uid,
              })
            } else {
              setModalPub(null)
              dispatch({
                type: "field",
                field: "visible",
                payload: false,
              })
            }
          },
        }
      )
    } else if (modalPub?.canSelfEnrol) {
      conditionalMutation?.mutate(
        {
          publicationUid: modalPub?.uid,
          data: personUid ? { personUid } : null,
        },
        {
          onSuccess: (res) => {
            if (res?.data?.uid && !personUid) {
              push(`/externals/${res.data.uid}`)
            } else {
              setModalPub(null)
              dispatch({
                type: "field",
                field: "visible",
                payload: false,
              })
            }
          },
        }
      )
    } else {
      conditionalMutation?.mutate(
        {
          publicationUid: modalPub?.uid,
          data: personUid ? { personUid } : null,
        },
        {
          onSuccess: (res) => {
            if (res?.data?.uid && !personUid) {
              dispatch({
                type: "field",
                field: "scormToFind",
                payload: modalPub?.id,
              })
              dispatch({
                type: "field",
                field: "enrolmentUid",
                payload: res?.data?.uid,
              })
            } else {
              setModalPub(null)
              dispatch({
                type: "field",
                field: "visible",
                payload: false,
              })
            }
          },
        }
      )
    }
    setTeamMemberToEnrol(null)
  }

  return (
    <Container style={{ marginTop: "5.75rem" }}>
      {!personQuery.isLoading && personQuery.data && (
        <>
          <p className="badge--meta">Hi {personQuery.data.firstName},</p>
          <Typography.Title level={3}>Let's Explore</Typography.Title>
        </>
      )}
      <Form
        className="filter"
        onFinish={(values) => {
          dispatch({
            type: "filter-field",
            field: "keyword",
            payload: values.keyword,
          })
        }}
      >
        <Form.Item name="keyword" className="filter__input filter__input--noMargin">
          <Input bordered={false} placeholder="Search..." prefix={<SearchOutlined />} />
        </Form.Item>
        <Space>
          <Button type="primary" loading={publicationsQuery.isLoading} htmlType="submit">
            Search
          </Button>
          <Select
            className="onMobile--hide"
            mode="multiple"
            allowClear
            style={{ minWidth: 200 }}
            placeholder="Select topic..."
            loading={filtersQuery.isLoading}
            defaultValue={state.filters.topics}
            onChange={(values) => {
              set(values)
              dispatch({
                type: "filter-field",
                field: "topics",
                payload: values,
              })
            }}
          >
            {filtersQuery.data &&
              filtersQuery.data?.topics?.map((topic) => (
                <Select.Option value={topic} key={generateKey(topic)}>
                  {topic}
                </Select.Option>
              ))}
          </Select>
          <Select
            defaultValue={state.filters.sort}
            onChange={(values) => {
              set(values)
              dispatch({
                type: "filter-field",
                field: "sort",
                payload: values,
              })
            }}
            className="onMobile--hide"
            allowClear
            style={{ minWidth: 200 }}
            placeholder="Select sort by..."
          >
            <Select.Option value={null}>None</Select.Option>
            <Select.Option value="created">Created</Select.Option>
            <Select.Option value="popularity">Popularity</Select.Option>
            <Select.Option value="price">Price</Select.Option>
            <Select.Option value="relevance">Relevance</Select.Option>
            <Select.Option value="title">Title</Select.Option>
          </Select>
        </Space>
      </Form>
      <Select
        className="onMobile--show"
        mode="multiple"
        allowClear
        style={{ width: "100%", marginBottom: "2rem" }}
        placeholder="Select topic..."
        loading={filtersQuery.isLoading}
        defaultValue={state.filters.topics}
        onChange={(values) => {
          set(values)
          dispatch({ type: "filter-field", field: "topics", payload: values })
        }}
      >
        {filtersQuery.data &&
          filtersQuery.data?.topics?.map((topic) => (
            <Select.Option value={topic} key={generateKey(topic)}>
              {topic}
            </Select.Option>
          ))}
      </Select>
      <Select
        defaultValue={state.filters.sort}
        onChange={(values) => {
          set(values)
          dispatch({
            type: "filter-field",
            field: "sort",
            payload: values,
          })
        }}
        className="onMobile--show"
        allowClear
        style={{ minWidth: 200 }}
        placeholder="Select sort by..."
      >
        <Select.Option value={null}>None</Select.Option>
        <Select.Option value="created">Created</Select.Option>
        <Select.Option value="popularity">Popularity</Select.Option>
        <Select.Option value="price">Price</Select.Option>
        <Select.Option value="relevance">Relevance</Select.Option>
        <Select.Option value="title">Title</Select.Option>
      </Select>

      {!publicationsQuery.isLoading && state.initialized ? (
        <>
          <TrainingModal
            visible={!!state?.training}
            event={state?.training}
            onCancel={() => dispatch({ type: "field", field: "training", payload: false })}
          />
          <Modal
            visible={state.visible}
            okButtonProps={{ style: { display: "none" } }}
            cancelButtonProps={{ style: { display: "none" } }}
            className="modal modal--noPadding"
            onCancel={() => {
              dispatch({ type: "field", field: "visible", payload: false })
              setModalPub(null)
            }}
          >
            {modalPub && (
              <>
                <Image
                  preview={false}
                  width="100%"
                  className="modal__image"
                  src={modalPub?.url}
                  fallback="https://images.unsplash.com/photo-1644169276891-235775ba3cad?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1471&q=80"
                />
                <div className="modal__body">
                  <Comment avatar={modalPub?.provider?.imageUrl} author="Provider" content={modalPub?.provider?.name} />
                  <Typography.Title level={2}>{modalPub?.title}</Typography.Title>
                  <div dangerouslySetInnerHTML={{ __html: modalPub?.description }} />
                  <Button
                    loading={
                      !teamMemberToEnrol &&
                      (integrationMutation.isLoading || publicationMutation.isLoading || awaitedScorm.loading)
                    }
                    disabled={
                      teamMemberToEnrol &&
                      (integrationMutation.isLoading || publicationMutation.isLoading || awaitedScorm.loading)
                    }
                    // loading={integrationMutation.isLoading || publicationMutation.isLoading || awaitedScorm.loading}
                    onClick={handleSelfEnrolment}
                    type="primary"
                  >
                    Enrol Now
                  </Button>
                  <Divider />
                  {listOfTeamsIManage ? (
                    <Space>
                      <Select
                        value={teamMemberToEnrol}
                        onChange={(value) => setTeamMemberToEnrol(value)}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                        }
                        placeholder="Enrol a team member"
                        style={{ width: "200px" }}
                        options={listOfTeamsIManage}
                      />
                      <Button
                        onClick={() => handleSelfEnrolment(teamMemberToEnrol)}
                        type="primary"
                        disabled={!teamMemberToEnrol}
                        loading={
                          !!teamMemberToEnrol && (integrationMutation.isLoading || publicationMutation.isLoading)
                        }
                      >
                        Enrol Someone Else
                      </Button>
                    </Space>
                  ) : null}
                  <Divider />
                  <p className="badge--meta">Topics</p>
                  <Space style={{ marginTop: "1rem" }}>
                    {modalPub?.topics &&
                      modalPub?.topics.map((topic) => (
                        <Tag key={generateKey(typeof topic === "string" ? topic : topic.value)} color="green">
                          {typeof topic === "string" ? topic : topic.value}
                        </Tag>
                      ))}
                  </Space>
                  <Divider />
                  <p className="badge--meta">Learning Outcomes</p>
                  <Timeline style={{ marginTop: "1rem" }}>
                    {modalPub?.outcomes?.map((outcome) => (
                      <Timeline.Item key={generateKey(outcome)}>{outcome}</Timeline.Item>
                    ))}
                  </Timeline>
                </div>
              </>
            )}
          </Modal>
          {publicationsQuery.data && !publicationsQuery.isError ? (
            <>
              <section className="featured hide-scroll-bar">
                {state.top.map((tPub) => (
                  <div
                    onClick={() => {
                      setModalPub(cardFactory(tPub))
                      dispatch({
                        type: "field",
                        field: "visible",
                        payload: true,
                      })
                    }}
                    key={generateKey(tPub.id)}
                    className="card"
                  >
                    <div
                      width="100"
                      src={tPub.image}
                      style={{
                        backgroundImage:
                          tPub.image || tPub?.featureImageUrl
                            ? `url(${tPub.image || tPub?.featureImageUrl})`
                            : "linear-gradient(to right, var(--ant-primary-color) , var(--ant-primary-color-active))",
                      }}
                      alt="recommended"
                      className="image"
                    />
                    <p className="title">{tPub.title || tPub?.content?.title}</p>
                    <p className="desc">{tPub.summary || tPub?.content?.summary}</p>
                  </div>
                ))}
              </section>
              <Row style={{ marginTop: "2rem" }} gutter={32}>
                <Col
                  xs={24}
                  md={eventsQuery?.isLoading || !eventsQuery?.data?.length ? 24 : 17}
                  style={{ marginBottom: "15rem" }}
                >
                  <Typography.Title level={3}>Recommended Courses (Self Enrollable)</Typography.Title>
                  <Row gutter={[16, 16]}>
                    {publicationsQuery.data
                      ?.slice(4)
                      ?.slice((state.page - 1) * state.pageSize, (state.page - 1) * state.pageSize + state?.pageSize)
                      .map((pub, i) => {
                        return (
                          <Col key={`${pub.id || pub.uid}-${pub?.title || pub?.content?.title}`} xs={24} md={12}>
                            <SmallCard
                              style={{ height: "100%", marginBottom: "1rem" }}
                              rows={2}
                              onClick={() => {
                                setModalPub(cardFactory(pub))
                                dispatch({
                                  type: "field",
                                  field: "visible",
                                  payload: true,
                                })
                              }}
                              {...cardFactory(pub)}
                            />
                          </Col>
                        )
                      })}
                  </Row>
                  <Pagination
                    defaultCurrent={state?.page}
                    total={publicationsQuery.data?.slice(4)?.length}
                    showSizeChanger
                    onChange={(page) =>
                      dispatch({
                        type: "field",
                        field: "page",
                        payload: page,
                      })
                    }
                    onShowSizeChange={(_, pageSize) => {
                      dispatch({
                        type: "field",
                        field: "pageSize",
                        payload: pageSize,
                      })
                    }}
                  />
                </Col>
                <Col xs={24} md={7}>
                  <Typography.Title level={3}>Formal Training (Approval Required)</Typography.Title>
                  {!eventsQuery?.isLoading ? (
                    <>
                      {eventsQuery?.data?.map((event) => (
                        <MinifiedCard
                          onClick={() =>
                            dispatch({
                              type: "field",
                              field: "training",
                              payload: event,
                            })
                          }
                          key={event?.uid}
                          title={event?.content?.title}
                          url={event?.featureImageUrl}
                          meta={`Training Events: ${event?.training?.length || 0}`}
                        />
                      ))}
                    </>
                  ) : (
                    <Skeleton />
                  )}
                </Col>
              </Row>
            </>
          ) : (
            <Empty
              image="/empty.png"
              imageStyle={{
                height: 60,
              }}
              description={<span>We&#39;re unable to fetch explorable publications right now</span>}
            >
              <Button type="primary">Back to my learning</Button>
            </Empty>
          )}
        </>
      ) : (
        <section className="featured hide-scroll-bar">
          <Card className="card" loading />
          <Card className="card" loading />
          <Card className="card" loading />
        </section>
      )}
    </Container>
  )
}
